module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"pgh.events","short_name":"pgh.events","description":"pgh.events is a music-focused collection of events, playlists, podcasts, videos blogs in Pittsburgh, Pennsylvania.","start_url":"/","background_color":"#ffd369","theme_color":"#222831","display":"minimal-ui","icon":"src/images/icon-2023.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a23d64cd22d08a3d2794255f911e8063"},
    },{
      plugin: require('../plugins/source-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
